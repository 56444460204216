export enum ApiPath {
  // Services
  // マナミル
  /** 遅刻欠席連絡画面：理由区分取得 */
  CONTACT_API_GET_REASON_LIST = '/guard/F30411/init',
  /** 遅刻欠席連絡画面：登録 */
  CONTACT_API_SEND = '/guard/F30411/submit',
  /** 入退室履歴画面：データ取得 */
  ENTRANCE_EXIT_HISTORY_GET = '/manager/F09024/init',
  /** イベントカレンダー画面：データ取得 */
  EVENT_CALENDAR_GET = '/manager/F08013/eventManage',
  /** イベントカレンダー画面：登録 */
  EVENT_CALENDAR_SINGLE_REGISTER = '/pop/F08006/saveSingle',
  /** イベントカレンダー画面：一括登録 */
  EVENT_CALENDAR_MULTIPLE_REGISTER = '/pop/F08006/saveGroup',
  /** イベントカレンダー画面：削除 */
  EVENT_CALENDAR_DELETE = '/pop/F08006/delete',
  /** イベントカレンダー画面：更新 */
  EVENT_CALENDAR_UPDATE = '/manager/F08005/updateEventSchedule',
  /** イベントカレンダー画面：代理登録 */
  EVENT_CALENDAR_SUBSTITUTE_REGISTER = '/pop/F08015/substituteRegister',
  /** イベントカレンダー画面：日程枠有無 */
  EVENT_CALENDAR_GET_IS_EXIST_FRAME = '/manager/F08013/getIsExistFrame',
  /** イベント一覧画面：データ取得 */
  EVENT_MANAGE_GET_EVENT_LIST = '/manager/F08009/getEventList',
  /** イベント一覧画面：詳細データ取得 */
  EVENT_MANAGE_GET_EVENT_DETAIL = '/manager/F08002/eventDetail',
  /** イベント一覧画面：既読 */
  EVENT_MANAGE_GET_EVENT_OVERVIEW_LIST = '/manager/F08011/init',
  /** イベント一覧画面：状況一覧取得 */
  EVENT_MANAGE_GET_EVENT_OVERVIEW_SUMMARY = '/manager/F08011/info',
  /** イベント作成画面：イベント登録 */
  EVENT_MANAGE_INSERT_UPDATE_EVENT = '/manager/F08002/editFront',
  /** イベント作成画面：テンプレート適用 */
  EVENT_MANAGE_APPLY_TEMPLATE = '/manager/F08002/tempFind',
  /** イベント作成画面：配信先登録 */
  EVENT_MANAGE_INSERT_UPDATE_DELIVERY_INFO = '/manager/F08010/saveFront',
  /** イベント状況確認画面：再通知 */
  EVENT_MANAGE_RESENT_EVENT_NOTIFICATION = '/manager/F08010/resentNotification',
  /** イベント一覧画面：イベント削除 */
  EVENT_MANAGE_DELETE_EVENT = '/manager/F08001/del',
  /** 保護者イベント画面：データ取得 */
  EVENTS_GUARD_GET_EVENT_INIT = '/guard/F30405/init',
  /** 保護者イベント画面：登録 */
  EVENTS_GUARD_REGISTER_EVENT = '/guard/F30405/update',
  /** 保護者イベント画面：キャンセル */
  EVENTS_GUARD_CANCEL_EVENTS = '/guard/F30410/delete',
  /** イベントテンプレート一覧画面：データ取得 */
  EVENT_TEMPLATE_GET_EVENT_TEMPLATE_LIST = '/manager/F08016/init',
  /** イベントテンプレート画面：データ取得 */
  EVENT_TEMPLATE_GET_EVENT_TEMPLATE = '/manager/F08017/init',
  /** イベントテンプレート画面：プレビュー情報取得 */
  EVENT_TEMPLATE_PREVIEW_EVENT_TEMPLATE = '/manager/F08016/getTmplateCont',
  /** イベントテンプレート画面：登録 */
  EVENT_TEMPLATE_REGISTER_EVENT_TEMPLATE = '/manager/F08017/addFront',
  /** イベントテンプレート画面：更新 */
  EVENT_TEMPLATE_UPDATE_EVENT_TEMPLATE = '/manager/F08017/updateFront',
  /** イベントテンプレート画面：削除 */
  EVENT_TEMPLATE_DELETE_EVENT_TEMPLATE = '/manager/F08016/deleteForce',
  /** 保護者カレンダー：データ取得 */
  GET_CALENDAR_DATAS_GET_CALENDAR_DATAS = '/guard/F30101/init',
  /** 連絡確認画面：褒めスタンプ一覧取得 */
  GET_COMPLIMENT_STAMP_GET = '/pop/F21021/init',
  /** お知らせ画面：配信対象生徒一覧取得 */
  GET_DELIVERY_STU_ID_LIST_GET = '/manager/F09020/getDeliveryStuIdList',
  /** 保護者お知らせ一覧画面：データ取得 */
  GET_GUARD_NOTIFICATIONS_GET_NOTIFICATIONS = '/guard/F30421/getNotifications',
  /** 保護者お知らせ一覧画面： アプリバッジ更新 */
  UPDATE_GUARD_APP_UNREAD_BADGE = '/guard/F30421/sendMessage',
  /** 保護者お知らせ一覧画面： 一括既読用アプリバッジ更新 */
  UPDATE_GUARD_APP_UNREAD_BADGE_ALL = '/guard/F30421/sendMessageAll',
  /** 保護者お知らせ詳細画面：お知らせデータ取得 */
  GET_GUARD_NOTIFICATIONS_GET_NOTICE_DETAILS = '/guard/F30113/init',
  /** 保護者お知らせ詳細画面：イベントデータ取得 */
  GET_GUARD_NOTIFICATIONS_GET_EVENT_DETAILS = '/guard/F30402/init',
  /** 保護者お知らせ詳細画面：褒めメッセージデータ取得 */
  GET_GUARD_NOTIFICATIONS_GET_COMPLIMENT_DETAILS = '/guard/F30423/init',
  /** store処理：お知らせデータ取得 */
  GET_NOTICE_DATAS_GET = '/manager/F09019/init',
  /** パスワード変更画面：更新時間取得 */
  GET_PASSWORD_UPDATE_TIME_GET = '/com/F40008/init',
  /** 連絡確認画面：データ取得 */
  GET_SEND_MESSAGE_DATAS_GET = '/manager/F21020/list',
  /** store処理：入退室ステータス再取得 */
  GET_STUDENT_ABS_STS_POST = '/manager/F21017/home',
  /** store処理：生徒データ取得 */
  GET_STUDENT_DATAS_POST = '/manager/F21017/init',
  /** store処理：未読件数取得 */
  GET_UNREAD_MESSAGES_GET = '/common/F40011/init',
  /** 保護者ホーム画面：お知らせ一括既読 */
  GUARD_NOTIFICATION_READ_ALL_READ = '/common/guardreadingsts/bulkRead',
  /** 保護者ホーム画面：イベント一括既読 */
  GUARD_NOTIFICATION_READ_ALL_READ_EVENTS = '/common/guardeventapplysts/bulkRead',
  /** 保護者ホーム画面：重要お知らせ一括既読 */
  GUARD_NOTIFICATION_READ_CHECKED_IMPORTANT_NOTICE = '/guard/F30113/noticeConfirm',
  /** 保護者基本設定画面：データ取得 */
  GUARD_PROFILE_GET_STUDENT = '/guard/F30416/init',
  /** 保護者基本設定画面：プロフィール画像更新 */
  GUARD_PROFILE_UPDATE_IMAGE = '/guard/F30416/update',
  /** ログイン画面：ログイン処理 */
  LOGIN_API_LOGIN = '/sys/login',
  /** 教室切替モーダル：教室一覧取得 */
  LOGIN_API_GET_CLASSROOMS = '/common/F40013/init',
  /** 教室切替モーダル：教室切替処理 */
  LOGIN_API_SELECT_CLASSROOM = '/sys/chooseOrg',
  /** 生徒切替モーダル：生徒切替処理 */
  LOGIN_API_SELECT_STUDENT = '/guard/F30002/save',
  /** 規約同意画面：データ取得 */
  LOGIN_API_INIT_TERMS_OF_USE = '/common/F40021/init',
  /** 規約同意画面：規約同意処理 */
  LOGIN_API_REGISTER_TERMS_OF_USE = '/common/F40021/reset',
  /** ログアウト処理 */
  LOGOUT_API_LOGOUT = '/common/logout',
  /** 連絡確認一覧画面：データ取得 */
  MESSAGES_API_GET_MESSAGES = '/manager/F21024/init',
  /** 連絡確認モーダル：詳細データ取得 */
  MESSAGES_API_GET_MESSAGE_DETAIL = '/pop/F21025/detail',
  /** 連絡確認モーダル：更新処理 */
  MESSAGES_API_SEND_MESSAGE_DETAIL_UPDATE = '/pop/F21025/update',
  /** 連絡確認モーダル：通知処理 */
  MESSAGES_API_SEND_MESSAGE_DETAIL_NOTICE = '/pop/F21025/notice',
  /** お知らせ作成画面：登録処理 */
  NOTICE_DATAS_UPDATE = '/manager/F09020/go',
  /** お知らせ画面：既読状況取得 */
  NOTICE_READING_STS_API_GET_READING_STS_LIST = '/manager/F09022/init',
  /** バージョン情報モーダル：バージョン情報取得 */
  OTHERS_API_GET_VERSION = '/common/versioninfo/init',
  /** キャンペーン応募画面：登録処理 */
  REGISTER_CAMPAIGN_APPLY_CAMPAIGN = '/guard/F30420/register',
  /** 代理入退室登録画面：登録処理 */
  REGISTER_ENTRANCE_EXIT_UPDATE = '/manager/F21026/submit',
  /** 連絡確認画面：メッセージ送信 */
  SEND_MESSAGE_INSERT = '/manager/F21020/sendMail',
  /** 生徒詳細画面：詳細情報取得 */
  STUDENT_DETAIL_GET = '/manager/F21018/init',
  /** 生徒詳細画面：更新処理 */
  STUDENT_DATAS_UPDATE = '/manager/F21018/update',
  /** パスワード変更画面：更新処理 */
  UPDATE_PASSWORD_UPDATE_PASSWORD = '/com/F40008/update',
  /** パスワード変更画面：初回ログイン処理 */
  UPDATE_PASSWORD_UPDATE_FST_LOGIN_FLG = '/com/F40008/updateStatus',
  /** パスワード変更画面：生徒数取得処理 */
  UPDATE_PASSWORD_GET_STUDENT_COUNT = '/com/F40008/getStuNumber',
  /** GakkenIDお問い合わせ画面：初期表示処理 */
  GET_INQUIRY_INIT = '/inquiry/F50001/init',
  /** GakkenIDお問い合わせ画面：登録処理 */
  REGISTER_INQUIRY = '/inquiry/F50002/submit',

  // マナミルスタディ
  /** マナミルスタディトップ：科目リスト取得 */
  GET_SUBJECT_LIST = '/cloudroom/subject',
  /** 級・集・ページ選択：選択可能教材取得 */
  GET_SELECTABLE_MATERIALS = '/cloudroom/selectableMaterial',
  /** 生徒詳細：英語無料体験：登録 */
  ENGLISH_FREE_TRIAL_REGISTER = '/cloudroom/englishTrial/register',
  /** 生徒詳細：英語無料体験：通知 */
  ENGLISH_FREE_TRIAL_NOTICE = '/cloudroom/englishTrial/notice',
  /** 級・集・ページ選択：入力コードチェック */
  CHECK_INPUT_CODE = '/cloudroom/checkInputCode',
  /** マナミルスタディ：学習履歴 */
  GET_CLOUDROOM_HISTORY = '/cloudroom/learnHistory',
  PUT_CLOUDROOM_HISTORY_REGISTER = '/cloudroom/learningHistoryRegister',
  /** マナミルスタディ：リリース制御 */
  GET_TADA_STUDY = '/cloudroom/tadaStudy',
  /** 公開範囲設定取得 */
  GET_CONTENTS_ACCESS_CTRL = '/cloudroom/contentsAccessctrl',

  // Modules
  /** 保護者情報更新：生徒データ取得 */
  APP_STATUS_GUARD_MODULE_LOAD_CHILDREN = '/guard/F30002/init',
  /** 保護者情報更新：チャンネルデータ取得 */
  CHANNEL_MODULE_GET_CHANNEL_LIST = '/guard/F30419/init',
  /** 保護者情報更新：チャンネル詳細データ取得 */
  CHANNEL_MODULE_GET_CHANNEL_DETAIL = '/guard/F30420/init',
  /** 保護者情報更新：予約済みイベントデータ取得 */
  RESERVED_EVENT_MODULE_GET_RESERVED_EVENT_DATAS = '/guard/F30409/init',
  /** 保護者情報更新：未読件数取得 */
  UNREAD_COUNT_MODULE_GET_UNREAD_COUNT = '/guard/F30421/unreadCount',

  // リンク
  /** メンテナンス情報 */
  ANNOUNCE_JSON = 'https://manamiru-announce-prd.s3.ap-northeast-1.amazonaws.com/announce.json',
  /** メンテナンスアイコン */
  ANNOUNCE_ICON = 'https://manamiru-announce-prd.s3.ap-northeast-1.amazonaws.com/icons/',
  /** PDFビューワー */
  PDF_VIEWER = '/plugins/pdfjs-2.5.207-es5-dist/web/viewer.html',
  /** 登録情報変更 */
  GE_APPLY = '/ge-apply',
  /** ログインURL */
  LOGIN_MANAMIRU = '/login/manamiru',
  /** ログインID確認ヘルプ */
  INQUIRY_F50001 = '/inquiry/F50001.html',
  /** パスワードがわからないリンク */
  COMMON_F40006 = '/common/F40006.html',
  /** イベント作成(IFrame) */
  MANAGER_F80001 = '/manager/F08001.html',
  /** イベント配信先設定(IFrame) */
  MANAGER_F80009 = '/manager/F08009.html',
  /** イベントテンプレート(IFrame) */
  MANAGER_F80016 = '/manager/F08016.html',
  /** QRコード表示 */
  MANAGER_F09002_QR_CODE = '/manager/F09002/qrcode',
  /** 保護者カレンダー取得 */
  GUARD_F30101 = '/guard/F30101.html',
  /** チャンネルリスト取得 */
  GUARD_F30419 = '/guard/F30419.html',
}
